import React from "react";
import styled from 'styled-components';
import UnstyledLink from './UnstyledLink.js';


const PostItemStyled = styled.div`
    width: 100%;
    margin-top: 5vh;
    font-size: clamp(0.5rem, 2vw + 0.5rem, 1.1rem);
    display: flex;
    flex-direction: row;
`;

const PostItemContent = styled.div`
    height: 100%;
    width: 100%;
`;

const PostItemDescription = styled.div`
  text-align: justify;
`;


const PostItem = (props) => {
  return (
    <PostItemStyled>
        <PostItemContent>
          <h2 className="blog-post-title">
            <UnstyledLink to={props.to}>{props.title}</UnstyledLink>
          </h2>
          <p className="blog-post-meta">{props.date}</p>
          <PostItemDescription>
            {props.children}
          </PostItemDescription>
        </PostItemContent>
    </PostItemStyled>
  );
}
export default PostItem;
