import React from "react";
import PostItem from './PostItem.js';
import styled from 'styled-components';

const PostItemListStyled = styled.div`
    display: flex;
    flex-direction: row;
    font-size: clamp(0.5rem, 2vw + 0.5rem, 1.7rem);
    font-family: Space Mono, monospace;
    width: 70vw;
    margin-left: 15vw;
`;


const UnorderedList = styled.ul`
    width: 100%;
    list-style-type: none;
    padding-left: 0;
    padding-right: 0;
    margin-top: 0vh;
    margin-bottom: 5vh;
`;


const PostItemList = (props) => {
  return (
      <PostItemListStyled>
        <UnorderedList>
          <li>
            <PostItem
              title="BluGreen.Cloud - Visualisation"
              author="Joel"
              date="January 2, 2023"
              to="/blugreencloud-viz"
            >
              <p>
                Photosynthesis is a vital process that allows plants and algae to produce their own food and absorb carbon in the process. However, until now, it has been difficult to see this process in real time. Blugreen.cloud's photobioreactor allows everyone to see the carbon capture or emission of cyanobacteria in real-time.
              </p>
            </PostItem>
          </li>
          <li>
            <PostItem
              title="Wisdom of odds"
              author="Joel"
              date="October 12, 2023"
              to="/wisdom-of-odds"
            >
              <p>
                A look at the evolution of public opinion in political and artistic events. From elections, to the Oscars, how does the opinion of crowd change over time?
              </p>
            </PostItem>
          </li>
        </UnorderedList>
      </PostItemListStyled>
  );
}

export default PostItemList;
