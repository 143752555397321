import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PageNotFound from './components/PageNotFound';
import CavePage from './pages/CavePage';
import PostPage from './pages/PostPage';
import PostWisdom from './pages/PostWisdom';


function App() {
  return (
    <div className="App">
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<CavePage/>}/>
            <Route path="/blugreencloud-viz" element={<PostPage/>}/>
            <Route path="/wisdom-of-odds" element={<PostWisdom/>}/>
            <Route path='*' element={<PageNotFound/>} />
          </Routes>
        </div>
      </Router>      
    </div>
  );
}

export default App;
