import React from 'react';
import styled from 'styled-components';
import Header from '../components/Header.js';
import Image from '../components/Image.js';
import CaveFlask from '../images/cave_flask.png'
import ChartImage from '../images/2022-01-10-chart.png'
import CircleImage from '../images/2022-01-11-circle.png'
import Footer from '../components/Footer.js';


const PostPageStyle = styled.div`
  font-size: clamp(0.5rem, 2vw + 0.5rem, 1.7rem);
  font-family: Space Mono, monospace;
  display: flex;
  flex-direction: column;
  background: #E6F5EE;
  color: #2A3230;
  min-height: 100vh;
`;

const PostPageBodyStyle = styled.div`
  font-size: clamp(0.5rem, 2vw + 0.5rem, 1.1rem);
  text-align: justify;
  margin-top: 5vh;
  margin-bottom: 5vh;
  margin-left: 10vw;
  margin-right: 10vw;  
`;

const PostPage = () => {
  const subtitles = [
    "A journey towards carbon capture transparency"
  ];
  return (
    <html>
        <PostPageStyle>
          <Header
            title="BluGreen.Cloud - Visualisation"
            subtitles={subtitles}
          />
          <Image
            image={CaveFlask}
            image_alt="Cave with flask"
          />
          <PostPageBodyStyle>
            <p>
              When I set out to dedicate a year to personal projects, I had a general idea of the types of skills I wanted to acquire, but wasn't quite sure which specific direction to take. To guide my learning, I decided to let necessity be the mother of invention, allowing me to explore new fields and develop my skills in ways that were both meaningful and directly applicable to my goals.
            </p>
            <p>
              The environment has always been a topic of great interest to me, particularly the ways in which our actions can have unseen or indirect effects on the planet. One area of particular concern to me is carbon capture, which is often used as a way to offset the carbon emissions of individuals and businesses. However, I've often found myself questioning the transparency of these carbon offsetting services, wondering how and when the carbon is actually being captured and stored. This curiosity ultimately led me to delve deeper into the topic, and ultimately gave birth to this project, BluGreen.Cloud.
            </p>
            <p>
              The driving force behind the creation of BluGreen.Cloud was the desire to see the carbon capture process in action. After researching different options, I discovered that cyanobacteria, specifically Spirulina platensis, would be a good photosynthetic organism to use due to its ability to be easily sealed and its air flow easily controlled. This made it the perfect organism for this project, which aims to provide real-time visualization of carbon capture and emissions using photosynthetical organisms.
            </p>
            <p>
              This prototyped photobioreactor, which houses a culture of Spirulina Platensis, has two distinct stages. The first stage is the Air Renewal phase, during which air is pumped into the bioreactor. The second stage is the Sealed phase, during which there is no exchange of air between the bioreactor and the outside environment. The chart below illustrates the change in CO2 parts per million (ppm) over time during each of these two stages.
            </p>
            </PostPageBodyStyle>
            <Image
              image={ChartImage}
              image_alt="Chart"
            />
            <PostPageBodyStyle>
            <p>
              As you can see from the chart, during the Air Renewal phase, there is an increase in CO2 ppm, indicating that the air outside the bioreactor has a higher concentration of CO2. On the other hand, during the Sealed phase, the cyanobacteria consumes the CO2 present in the newly pumped air, leading to a decrease in the proportion of CO2 within the bioreactor chamber. Now, let's take a closer look at the data represented in the circle.
            </p>
            </PostPageBodyStyle> 
            <Image
              image={CircleImage}
              image_alt="Circle"
            />
            <PostPageBodyStyle>
            <p>
              The circle in the graph is activated once the Sealed phase begins. It provides real-time visualization of the percentage of CO2 captured (represented in green) or emitted (represented in red) in the bioreactor. The percentage is calculated by comparing the initial CO2 level at the start of the Sealed phase to the most recent CO2 level observed. This allows you to see, in real-time, the efficiency of the carbon capture process.
            </p>
            <p>
              This real-time visualization feature provides transparency and allows anyone to clearly understand the carbon capture process. The goal of BluGreen.Cloud is to demonstrate the importance of transparency in this industry. If you have any feedback on the project, feel free to send them to info@blugreen.cloud.
            </p>
            </PostPageBodyStyle> 
            <Footer/> 
        </PostPageStyle>
    </html>
)
}

export default PostPage;
