import React from 'react';
import styled from 'styled-components';
import Header from '../components/Header.js';
import Image from '../components/Image.js';
import CaveCandy from '../images/candy_flask.png';
import CollectDiagram from '../images/wisdom_of_odds_collection.png'
import VizDiagram from '../images/wisdom_of_odds_visualization.png'
import OscarsChart from '../images/oscars_2022.png'
import Footer from '../components/Footer.js';
import Dashboard from '../images/dashboard.png';
import UnstyledLink from '../components/UnstyledLink.js';


const PostWisdomStyle = styled.div`
  font-size: clamp(0.5rem, 2vw + 0.5rem, 1.7rem);
  font-family: Space Mono, monospace;
  display: flex;
  flex-direction: column;
  background: #E6F5EE;
  color: #2A3230;
  min-height: 100vh;
`;

const PostWisdomBodyStyle = styled.div`
  font-size: clamp(0.5rem, 2vw + 0.5rem, 1.1rem);
  text-align: justify;
  margin-top: 5vh;
  margin-bottom: 5vh;
  margin-left: 10vw;
  margin-right: 10vw;  
`;

const PostWisdom = () => {
  const subtitles = [
    "A look at the tranformation of public opinion"
  ];
  return (
    <html>
        <PostWisdomStyle>
          <Header
            title="Wisdom of Odds"
            subtitles={subtitles}
          />
          <Image
            image={CaveCandy}
            image_alt="Cave with swan"
          />
          <PostWisdomBodyStyle>
            <p>
              The concept of the Wisdom of Crowds has long intrigued me. This concept, popularized by James Surowiecki in his book, explored the remarkable accuracy with which a diverse crowd can predict outcomes, often outperforming most individual forecasts. For such collective intelligence to manifest, four essential characteristics must be present: diversity of opinions, independence, decentralization, and aggregation. However, ensuring the presence of these elements proves challenging in our increasingly interconnected world.
            </p>
            <p>
              Betting odds are a interesting mechanism of aggregating opinions of a large group. Specially because each participant has an incentive to be the best predictor one can be. Despite that odds may not always acturately reflect the actual probabilities of events, they nonetheless provide a picture of the overall opinion of a crowd.
            </p>
            <p>
              It was this interest on the variation of public opinion, coupled with the will to improve my data engineering skills, that made me work in a small project to make those odds visible. To achieve this, I used AWS to build a data pipeline in order to extract and transform betting data to make it visible from a Metabase instance to everyone. 
            </p>
          </PostWisdomBodyStyle>
            <Image
              image={CollectDiagram}
              image_alt="Collection diagram"
              maxHeight="70vh"
            />
          <PostWisdomBodyStyle>
            <p>
              As is visible from the previous diagram, the pipeline begins with a scheduled AWS EventBridge rule. This rule triggers an AWS Step Functions state machine, progressing through three distinct states: assessing the API's availability; retrieving the event book data, which contains information about the event; and fetching the event catalogue data, which holds the most recent odds. Once these two files are uploaded to S3, a corresponding Lambda is triggered, with an S3 trigger, for data transformation. This procedure decomposes the raw JSON information into three table-like files: one containing the event details, the second extracting information about the participants, and the third breaking down the latest odds data. Finally, this transformed data is stored in a separate S3 bucket.
            </p>
          </PostWisdomBodyStyle>
            <Image
              image={VizDiagram}
              image_alt="Visualisation diagram"
            />
          <PostWisdomBodyStyle>
            <p>
            At the access layer, an instance of Metabase is used to work in conjunction with AWS Glue and AWS Athena to retrieve and visualize data stored in S3.
            </p>
            </PostWisdomBodyStyle>
            <Image
              image={OscarsChart}
              image_alt="Oscars 2002 diagram"
            />
          <PostWisdomBodyStyle>
            <p>
              In the previous chart, you can observe how the likelihood of the movie 'The Power of the Dog' decreased as the ceremony approached. You can check the overall dashboard in the following image.
            </p>
          </PostWisdomBodyStyle>
          <Image
              image={Dashboard}
              image_alt="Odds dashboard"
            />
            
            <Footer/> 
        </PostWisdomStyle>
    </html>
)
}

export default PostWisdom;
