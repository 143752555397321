import React from 'react';


const PageNotFound = () => (
    <div>
        <h1>404 - Not Found!</h1>
    </div>
)

export default PageNotFound;
