import React from 'react';
import styled from 'styled-components';
import Header from '../components/Header.js';
import PostItemList from '../components/PostItemList.js';
import Footer from '../components/Footer.js';
import Image from '../components/Image.js';
import Cave from '../images/cave.png';


const MainPageBodyStyle = styled.body`
  font-size: clamp(0.5rem, 2vw + 0.5rem, 1.7rem);
  font-family: Space Mono, monospace;
  color: #2A3230;
  display: flex;
  flex-direction: column;
  background: #E6F5EE;
  min-height: 100vh;
`;

const Main = () => {
    const subtitles = [
      "A personal blog of an engineer",
      "For those who forgot their prototype at home"
    ];
    return (
    <html>
      <head>
        <title>Prototypeless</title>
      </head>
      <MainPageBodyStyle>
        <Header title="Prototypeless" subtitles={subtitles}/>
        <Image image={Cave} image_alt="Cave with laptop" />
        <PostItemList/>
        <Footer/> 
      </MainPageBodyStyle>
    </html>
)}
export default Main;
