import React, { useState, useEffect } from 'react';

function SwitchingText({ phrases }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    // Set up an interval to switch the text every 3 seconds
    const interval = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % phrases.length);
    }, 3000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(interval);
  }, [phrases]);

  return <h4>{phrases[currentIndex]}</h4>;
}

export default SwitchingText;
