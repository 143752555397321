import React from "react";

import styled from 'styled-components';

import SwitchingText from './SwitchingText';



const StyledHeader = styled.div`
    height: 20vh;
    width: 80vw;
    margin-left: 10vw;
    display: flex;
    flex-direction: column;
    font-size: clamp(0.5rem, 2vw + 0.5rem, 1.4rem);
    font-family: Space Mono, monospace;
    text-align: center;
`;

const Header = (props) => {
  return (
    <StyledHeader>
      <header>
        <h1>{props.title}</h1>
        { props.subtitles? (<SwitchingText phrases={props.subtitles} />) : null}
      </header>
    </StyledHeader>
  );
}
export default Header;

