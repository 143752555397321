import React from "react";

import styled from 'styled-components';


const FooterStyled = styled.div`
    height: 10vh;
    font-size: clamp(0.5rem, 2vw + 0.5rem, 1rem);
`;

//<p>Copyright 2023 Expedition to the unknown</p>
const Footer = (props) => {
  return (
    <footer>
        <FooterStyled>
        </FooterStyled>
    </footer>
  );
}
export default Footer;
