import React from "react";
import styled from 'styled-components';

const ImageStyle = styled.img`
    align-self: center;
    max-height: ${props => props.maxHeight || '50vh'};
    max-width: ${props => props.maxWidth || '100vw'};
`;

const Image = (props) => {
  return (
    <ImageStyle 
        src={props.image} 
        alt={props.image_alt} 
        maxHeight={props.maxHeight} 
        maxWidth={props.maxWidth} 
    />
  );
}

export default Image;
